import React from 'react';
import './style.css';
import OfferCard from '../About/OfferCard';
import schedule from "../../assets/scheduling.jpeg"
import second from "../../assets/21.jpeg"
import homedeliver from "../../assets/homedeliver.jpg"
import consfidantial from "../../assets/confidential.jpeg"
import record from "../../assets/record.jpeg"
import first from "../../assets/first.jpeg"
import third from "../../assets/third.jpeg"
import reminder from "../../assets/reminder.png"
import ambulance from "../../assets/ambulance.jpeg"


const offers = [
    {
        image: first,
        title: 'Personalized Health Management',
        content: 'Tailored healthcare experience with customized scheduling and health tracking.',
    },
    {
        image: second,
        title: 'Convenient Access to Experts',
        content: 'Connect with top doctors and specialists easily.',
    },
    {
        image: third,
        title: 'Streamlined Communication',
        content: 'Seamless interaction with your healthcare team from home.',
    },
    {
        image: record,
        title: 'Health Records at Your Fingertips',
        content: 'Secure, digital access to your medical history and test results.',
    },
    {
        image: schedule,
        title: 'Comprehensive Health Insights',
        content: 'Integrates with health tracking devices for a holistic view.',
    },
    {
        image: reminder,
        title: 'Easy Scheduling and Reminders',
        content: 'Book, reschedule, or cancel appointments effortlessly with automated reminders.',
    },
    {
        image: consfidantial,
        title: 'Secure and Confidential',
        content: 'Advanced security measures to protect your personal health information.',
    },
    {
        image: homedeliver,
        title: 'Medicine Delivery',
        content: (
            <ul>
                <li><strong>Metropolitan Cities:</strong> Medicine delivered to your doorstep within 2 hours.</li>
                <li><strong>Smaller Cities:</strong> Medicine delivered within 24 hours.</li>
            </ul>
        ),
    },
    {
        image: ambulance,
        title: 'Emergency Services',
        content: 'Immediate transportation to the nearest healthcare facility in critical situations.',
    },
];

const OurServices = () => {
  return (
    <>
    <div className="MeetUrDoc-container">
            <header className="MeetUrDoc-header">
                <h1>MeetUrDoc: Your Health, Your Way</h1>
                <p className="subheading">Health on Your Fingertip</p>
            </header>
            <section className="MeetUrDoc-content">
                <h2>What We Offer:</h2>
                <div className="offer-cards-container">
                {offers.map((offer, index) => (
                    <OfferCard
                        key={index}
                        image={offer.image}
                        title={offer.title}
                        content={offer.content}
                    />
                ))}
            </div>
                <h2>Why Choose MeetUrDoc?</h2>
                <ul>
                    <li>User-Friendly Design: Accessible for everyone, regardless of tech-savviness.</li>
                    <li>24/7 Support: Dedicated customer support around the clock.</li>
                    <li>Innovative Solutions: Continuously updated with the latest healthcare technology advancements.</li>
                </ul>
                <h2>Experience Healthcare Like Never Before</h2>
                <p>
                    MeetUrDoc is committed to putting your health first, making it easier to stay informed, connected, and in control. 
                    Your health is always within reach, empowering you to make better decisions and live your best life.
                </p>
                <h2>Get Started Today</h2>
                <p>
                    Join the growing community of people taking charge of their health with MeetUrDoc. 
                    Visit our website or download our app to start your journey towards a healthier, more empowered you.
                </p>
                <p><strong>MeetUrDoc: Health on Your Fingertip.</strong></p>
            </section>
        </div>
        <div className="services-container">
      <h1>Our Services</h1> 
      <section className="service-section">
        <h2>Individual Services</h2>
        <p>MeetUrDoc offers a range of telemedicine services tailored to individual patients. Our platform allows you to consult with healthcare professionals from the comfort of your home, ensuring that you receive the medical attention you need without the inconvenience of travel.</p>
        <h3>Primary Care</h3>
        <p>Our primary care services include routine check-ups, preventive care, and management of chronic conditions. MeetUrDoc enables you to book appointments, receive medical advice, and get prescriptions refilled quickly and efficiently.</p>
        <h3>Specialist Consultations</h3>
        <p>We provide access to specialists in various fields such as cardiology, dermatology, and neurology. Through MeetUrDoc, you can schedule virtual consultations with experts who can diagnose and manage specific health issues.</p>
        <h3>Mental Health Support</h3>
        <p>Mental health is crucial for overall well-being. Our platform offers sessions with licensed therapists and psychiatrists who can help with issues such as anxiety, depression, and stress management.</p>
        <h3>How MeetUrDoc Helps</h3>
        <p>MeetUrDoc streamlines the process of accessing individual healthcare services. Our user-friendly interface makes it easy to find and book appointments with healthcare providers, attend virtual consultations, and manage your health records online.</p>
        <a href='/more-about-MeetUrDoc'><button className='More-Details'>More Details</button></a>
      </section>
      <section className="service-section">
        <h2>Clinical Services</h2>
        <p>MeetUrDoc supports clinical practices by offering tools for telehealth consultations, patient management, and streamlined administrative processes.</p>
        <h3>Telehealth Consultations</h3>
        <p>Our platform allows clinicians to conduct video consultations, reducing the need for in-person visits and making healthcare more accessible to patients. Clinicians can review patient histories, provide diagnoses, and follow up on treatment plans.</p>
        <h3>Patient Management</h3>
        <p>MeetUrDoc helps clinics manage patient records, appointment schedules, and treatment plans efficiently. Our platform ensures that all patient data is secure and easily accessible to authorized personnel.</p>
        <h3>Administrative Tools</h3>
        <p>We provide clinics with tools to handle billing, insurance claims, and other administrative tasks, reducing the workload on staff and allowing them to focus more on patient care.</p>
        <h3>How MeetUrDoc Helps</h3>
        <p>MeetUrDoc enhances the efficiency of clinical operations by integrating telemedicine into everyday practice. Our platform reduces no-show rates, improves patient engagement, and ensures continuity of care through seamless communication and record-keeping.</p>
        <a href="/service/organization"><button className='More-Details'>More Details</button></a>
      </section>
      <section className="service-section">
        <h2>Organizational Services</h2>
        <p>MeetUrDoc offers comprehensive telemedicine solutions for organizations, including corporate wellness programs, occupational health services, and remote employee healthcare.</p>
        <h3>Corporate Wellness Programs</h3>
        <p>Our corporate wellness programs aim to improve the health and well-being of employees. MeetUrDoc provides virtual health assessments, wellness coaching, and regular health monitoring to help employees stay healthy and productive.</p>
        <h3>Occupational Health Services</h3>
        <p>We offer occupational health services to ensure that employees are fit to work and that workplaces are safe. This includes pre-employment health screenings, workplace injury assessments, and health risk assessments.</p>
        <h3>Remote Employee Healthcare</h3>
        <p>For organizations with remote or dispersed workforces, MeetUrDoc offers telemedicine solutions that provide employees with access to healthcare services regardless of their location. This includes virtual consultations, mental health support, and chronic disease management.</p>
        <h3>How MeetUrDoc Helps</h3>
        <p>MeetUrDoc assists organizations in maintaining a healthy workforce by providing accessible healthcare services and wellness programs. Our platform supports employees’ health needs, enhances productivity, and reduces healthcare costs through early intervention and continuous care.</p>
        <a href='/more-about-MeetUrDoc'><button className='More-Details'>More Details</button></a>
      </section>
    </div>
    </>
  );
};

export default OurServices;

