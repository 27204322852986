import React, { useState } from 'react';
import styled from 'styled-components';
import { FaSearch, FaRegUser, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

const Container = styled.div`
  font-family: Arial, sans-serif;
`;

const Header = styled.header`
  background-color: #0056b3;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.h1`
  margin: 0;
  font-size: 1.5rem;
`;

const Nav = styled.nav`
  a {
    color: white;
    margin: 0 1rem;
    text-decoration: none;
  }
`;

const SearchBar = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  
  input {
    padding: 0.5rem;
    width: 100%;
    max-width: 500px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  button {
    background-color: #004494;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 0.5rem;
  }
`;

const Dashboard = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem;
`;

const Sidebar = styled.div`
  width: 25%;
  padding: 1rem;
  border-right: 1px solid #ddd;
`;

const Content = styled.div`
  width: 70%;
  padding: 1rem;
`;

const TabButton = styled.button`
  background: ${props => (props.active ? '#0056b3' : '#ddd')};
  color: ${props => (props.active ? 'white' : 'black')};
  border: none;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
`;

const FAQSection = styled.div`
  margin-top: 1rem;
  
  h2 {
    border-bottom: 2px solid #0056b3;
    padding-bottom: 0.5rem;
  }
  
  .faq-item {
    margin: 0.5rem 0;
    cursor: pointer;
    font-weight: bold;
  }

  .faq-answer {
    margin: 0.5rem 0;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
`;

const LiveChatButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
`;

const ContactForm = styled.div`
  margin-top: 1rem;
  
  h2 {
    border-bottom: 2px solid #0056b3;
    padding-bottom: 0.5rem;
  }

  form {
    display: flex;
    flex-direction: column;

    input, textarea {
      margin: 0.5rem 0;
      padding: 0.5rem;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    button {
      background-color: #0056b3;
      color: white;
      border: none;
      padding: 0.5rem;
      border-radius: 4px;
      cursor: pointer;
    }
  }
`;

const Footer = styled.footer`
  background-color: #f4f4f4;
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ddd;
  
  a {
    color: #0056b3;
    text-decoration: none;
    margin: 0 0.5rem;
  }
  
  .social-icons {
    margin: 1rem 0;
    
    i {
      font-size: 24px;
      margin: 0 0.5rem;
      color: #0056b3;
    }
  }
`;

const SupportComponent = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [expandedFAQ, setExpandedFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setExpandedFAQ(expandedFAQ === index ? null : index);
  };

  return (
    <Container>
      {/* <Header>
        <Logo>MeetUrDoc</Logo>
        <Nav>
          <a href="/">Home</a>
          <a href="/services">Services</a>
          <a href="/contact">Contact Us</a>
          <a href="/faqs">FAQs</a>
        </Nav>
      </Header> */}
      
      <SearchBar>
        <input type="text" placeholder="Search support articles..." />
        <button><FaSearch /></button>
      </SearchBar>
      
      <Dashboard>
        <Sidebar>
          <TabButton active={activeTab === 'overview'} onClick={() => setActiveTab('overview')}>Overview</TabButton>
          <TabButton active={activeTab === 'faqs'} onClick={() => setActiveTab('faqs')}>FAQs</TabButton>
          <TabButton active={activeTab === 'livechat'} onClick={() => setActiveTab('livechat')}>Live Chat</TabButton>
          <TabButton active={activeTab === 'contact'} onClick={() => setActiveTab('contact')}>Contact Us</TabButton>
        </Sidebar>
        
        <Content>
          {activeTab === 'overview' && <div>Welcome to the MeetUrDoc Support Center. How can we help you today?</div>}
          
          {activeTab === 'faqs' && (
            <FAQSection>
              <h2>Frequently Asked Questions</h2>
              <div className="faq-item" onClick={() => toggleFAQ(1)}>
                How do I create an account?
              </div>
              {expandedFAQ === 1 && (
                <div className="faq-answer">
                  To create an account, click on the 'Sign Up' button on the home page and follow the instructions.
                </div>
              )}
              <div className="faq-item" onClick={() => toggleFAQ(2)}>
                What should I do if I forget my password?
              </div>
              {expandedFAQ === 2 && (
                <div className="faq-answer">
                  Click on the 'Forgot Password' link on the login page and follow the instructions to reset your password.
                </div>
              )}
              {/* Add more FAQs as needed */}
            </FAQSection>
          )}
          
          {activeTab === 'livechat' && (
            <div>Live chat feature will be available here.</div>
          )}
          
          {activeTab === 'contact' && (
            <ContactForm>
              <h2>Contact Us</h2>
              <form>
                <input type="text" placeholder="Your Name" required />
                <input type="email" placeholder="Your Email" required />
                <input type="text" placeholder="Subject" required />
                <textarea placeholder="Your Message" rows="5" required></textarea>
                <button type="submit">Send Message</button>
              </form>
            </ContactForm>
          )}
        </Content>
      </Dashboard>
      
      <LiveChatButton>
        <FaRegUser />
      </LiveChatButton>
      
    </Container>
  );
};

export default SupportComponent;
